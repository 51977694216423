<template>
	<div class="shop-nav">
		<v-tree v-if="nav.length">
			<v-tree-node v-for="(item, i) in nav" :key="i" :item="item" />
		</v-tree>
	</div>
</template>

<script lang="ts" setup>
	const { shopNav: nav } = storeToRefs(useGlobalStore())

	// function onEnter(el: Element, done: () => void) {
	// 	const items = el.querySelectorAll('.tree__item')

	// 	if (!items.length) {
	// 		done()
	// 		return
	// 	}

	// 	$gsap
	// 		.timeline({
	// 			onComplete: done,
	// 		})
	// 		.to(items, {
	// 			autoAlpha: 1,
	// 			duration: 0.5,
	// 			stagger: 0.05,
	// 			x: 0,
	// 		})
	// }
</script>

<style lang="scss">
	// .shop-nav {
	// 	.tree__item {
	// 		visibility: hidden;
	// 		opacity: 0;
	// 		transform: translateX(20px);
	// 	}
	// }
</style>
